<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header headerName="巴林右旗牧户列表" @selectJob="selectJob" />

    <div class="w_full flex_row_between">
      <div class="w_20_p ph_20">
        <cascader class="w_full_i" 
          :clearable="cascaderClearable"
          @getSelectedOptions='getSelectedOptions' 
          @initOptions="initOptions"
        />
      </div>

      <div class="w_20_p text_right ph_20">
        <!-- <el-button type="primary" size="small" @click="getUserInfoList()">搜索</el-button> -->
        <!-- <el-button type="warning" size="small" @click="reset()">重置</el-button> -->
      </div>
    </div>

    <div class="content_box w_full h_full-100 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_full h_full p_10 border_box bgs_full chart_box_bgi_1"> 
        <div class="w_full h_full relative">
          <el-table :data="userList" border style="width: 100%; height:100%" max-height="1000">
            <el-table-column type="index" label="序号" width="100" align="center" />
            <el-table-column prop="id" label="牧户ID" width="120" align="center" />
            <el-table-column prop="" label="头像" width="70" align="center">
              <template slot-scope="scope">
                <div class="w_50 h_50 relative">
                  <div class="w_50 h_50 absolute top_0 left_0 z_999">
                    <el-image
                      class="full"
                      :src="filePath + scope.row.avatarUrl"
                      :preview-src-list="srcList"
                      @click="handleClickAvatar(scope.$index)"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="nickName" label="姓名" width="300" align="center" />
            <!-- <el-table-column prop="gender" label="性别" width="70" align="center" >
              <template slot-scope="scope">
                {{ formatGender(scope.row.gender) }}
              </template>
            </el-table-column> -->
            <el-table-column prop="phone" label="电话" width="200" align="center" />
            <el-table-column prop="" label="地址" align="left">
              <template slot-scope="scope">
                {{ scope.row.townName + ' / ' + scope.row.villageName +  ' / ' + scope.row.groupName }}
              </template>
            </el-table-column>
          </el-table>

          <div class="absolute right_10 bottom_0">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParams.page"
              :page-sizes="[20, 50, 100, 200]"
              :page-size="queryParams.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
          
        </div> 
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'

  import { filePath } from "@/const"

  import { getUserInfoList } from '@/api/mu-hu/mu-hu.js'

  export default {
    name: 'MuHuList',
    mixins: [defMixin, componentsMixin],
    data() {
      return {
        filePath: filePath,
        cascaderClearable: false,
        total: 0,
        userList: [],

        activeRowIndex: null,

        srcList: [],

        queryParams: {
          adminAreaCode: '150423000000',
          level: 2,
          page: 1,
          size: 20
        },

        recordYiList: [],
        activeIndex: 0,
      }
    },

    async mounted() {
      await this.getUserInfoList()
    },

    methods: {
      selectJob(jobId) {
        this.jobId = jobId;
      },

      initOptions() {
        this.queryParams = {
          adminAreaCode: '150423000000',
          level: 2,
          page: 1,
          size: 200
        }
        this.getUserInfoList()
      },

      reset() {
        this.cascaderClearable = true
        this.queryParams = {
          adminAreaCode: '150423000000',
          level: 2,
          page: 1,
          size: 200
        }

        this.getUserInfoList()
      },

      // 获取 免疫台账信息
      async getUserInfoList() {
        const loading = this.$loading({
          lock: true,
          text: '数据加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        setTimeout(() => {
          loading.close();
        }, 1000);

        let res = await getUserInfoList(this.queryParams)
        try {
          if (res.code == 1000) {
            this.total = res.data.pagination.total
            this.userList = res.data.list

            this.handleClickAvatar(0)
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 监听 区域 子组件 发射过来的事件
      getSelectedOptions(list) {
        if (list.length == 1) {
          this.queryParams.adminAreaCode = list[0].code
          this.queryParams.level = 3
        }

        if (list.length == 2) {
          this.queryParams.adminAreaCode = list[1].code
          this.queryParams.level = 4
        }

        if (list.length == 3) {
          this.queryParams.adminAreaCode = list[2].code
          this.queryParams.level = 5
        }

        this.getUserInfoList()
      },

      // 跳转页面
      handleSizeChange(val) {
        this.queryParams.size = val

        this.getUserInfoList()
      },

      // 更新 分页条数
      handleCurrentChange(val) {
        this.queryParams.page = val

        this.getUserInfoList()
      },

      // 跳转到牧户详情页
      handleClickMuhu(item) {
        this.goToMuHuPage(null, null, null, item.id)
      },

      // 点击 牧户 头像
      handleClickAvatar(index) {
        if (this.userList && this.userList.length) {
          let img = this.filePath + this.userList[index].avatarUrl

          let list = [img]

          this.srcList = list
        }
      }

      
    }
  }
</script>

<style>
.el-pagination__total, .el-pagination__jump { color: orange !important; }

.el-image-viewer__mask { background-color: #fff !important; }

/* .el-image-viewer__img {
  transform-origin: center center !important;
  transform: scale(1) rotate(90deg) !important; 
} */
</style>
